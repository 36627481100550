import { React, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from "react-toastify";
import TicketImg1 from '../images/ticket-img1.svg'
import TicketImg2 from '../images/ticket-img2.svg'
import TicketImg3 from '../images/ticket-img3.svg'
import NextArrow from '../images/next-arrow.svg'
import Cross from '../images/cross.svg'
import FreeEntryIcon from '../images/free-entry-icon.svg'
import StripeCheckout from 'react-stripe-checkout';
import { Globalvar } from '../components/global';
import axios from "axios";
import secureLocalStorage from 'react-secure-storage';
import StripeForm from '../components/stripeCheckout';
import { loadStripe } from "@stripe/stripe-js";
import Modal from 'react-bootstrap/Modal';
import useDocumentTitle from '../components/useDocumentTitle';
import { Elements, useElements } from "@stripe/react-stripe-js";
import { addToCart, initiateCheckout } from '../utils/meta/metaPixelEvent';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


export default function SubscriptionPlan() {
    useDocumentTitle('Subscription Plan');
    const siteId = process.env.REACT_APP_SITE_ID;
    const APIURL = process.env.REACT_APP_API_URL;
    const [scroll, setScroll] = useState(false);
    const [DrawData, setDrawData] = useState([]);
    const userData = secureLocalStorage.getItem('LogObject');
    const [paymentDetails, setPaymentDetails] = useState(false);
    const [SubscriptionPlan, setSubscriptionPlan] = useState(true);
    const [Freemodal, setFreemodal] = useState(false);
    const [isActive1, setIsActive1] = useState(false);
    const [isActive2, setIsActive2] = useState(true);
    const [isActive3, setIsActive3] = useState(false);
    const [isActive4, setIsActive4] = useState(false);
    const [amount, setAmount] = useState(10);
    const [entries, setEntries] = useState(11);
    const [consent1, setConsent1] = useState(false);
    const [consent2, setConsent2] = useState(false);
    const [consent3, setConsent3] = useState(false);
    const [oneShow, setOneShow] = useState(false);
    const [isStripe, setStripe] = useState(false);
    const [isoneoff, setIsoneoff] = useState(false);
    const [email, setEmail] = useState(secureLocalStorage.getItem('LogObject').email);
    const [UserID, setUserId] = useState(secureLocalStorage.getItem('LogObject').userID);
    console.log(secureLocalStorage.getItem('LogObject'));
    const navigate = useNavigate();
    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    function hideModal() {
        setOneShow(false);
    }

    Globalvar.amount = 10;
    Globalvar.entries = 11;

    useEffect(() => {
        window.addEventListener("scroll", () => {
          setScroll(window.scrollY > 10);
        });
        axios.get(APIURL + '/sites/' + siteId + '/draws').then(res => {
    
          let alldraw = res.data.content;
          let activeDraw = res.data.content.filter(item => item.drawIsClosed != 1);
          let selectedDraw = {};
          if (activeDraw.length > 0) {
            selectedDraw = activeDraw[0]
          } else {
            selectedDraw = alldraw[0];
          }
          setDrawData(selectedDraw);
        });
      }, []);

    const handleClick = (param, value, tickets) => {

        switch (param) {
            case 'first':
                setAmount(value);
                Globalvar.amount = value;
                Globalvar.entries = tickets;
                secureLocalStorage.setItem('amount', value);
                secureLocalStorage.setItem('entries', tickets);
                setEntries(tickets)
                setIsActive1(true);
                setIsActive2(false);
                setIsActive3(false);
                setIsActive4(false);
                return;
            case 'second':
                setAmount(value);
                Globalvar.amount = value;
                Globalvar.entries = tickets;
                secureLocalStorage.setItem('amount', value);
                secureLocalStorage.setItem('entries', tickets);
                setEntries(tickets)
                setIsActive1(false);
                setIsActive2(true);
                setIsActive3(false);
                setIsActive4(false);
                return;
            case 'third':
                setAmount(value);
                Globalvar.amount = value;
                Globalvar.entries = tickets;
                secureLocalStorage.setItem('amount', value);
                secureLocalStorage.setItem('entries', tickets);
                setEntries(tickets)
                setIsActive1(false);
                setIsActive2(false);
                setIsActive3(true);
                setIsActive4(false);
                return;
            case 'fourth':
                setAmount(value);
                Globalvar.amount = value;
                Globalvar.entries = tickets;
                secureLocalStorage.setItem('amount', value);
                secureLocalStorage.setItem('entries', tickets);
                setEntries(tickets)
                setIsActive1(false);
                setIsActive2(false);
                setIsActive3(false);
                setIsActive4(true);
                return;
            default:
                setEntries(11)
                setAmount(10);
                setIsActive1(false);
                setIsActive2(true);
                setIsActive3(false);
                setIsActive4(false);
                return;
        }
    };

    function toPaymentDetails() {
        setSubscriptionPlan(false)
        setPaymentDetails(true);

        // Data Layer Push for Add to Cart event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'add_to_cart',
          'ecommerce': {
            'user_id': userData?.userID ? userData?.userID : 'Guest User',
            'user_email': userData?.email ? userData?.email : 'Guest User',
            'items': [{
              'item_name': DrawData.drawName,
              'item_id': DrawData.drawID,
              'price': amount,
              'item_brand': 'Sport',
              'item_category': DrawData.drawCategoryID === 1 ? 'Fixed Cash Prize' : DrawData.drawCategoryID === 2 ? 'Split Cash Prize' : DrawData.drawCategoryID === 3 ? 'Experiential' : DrawData.drawCategoryID === 4 ? 'Hybrid' : '',
              'item_variant': !isoneoff ? 'Subscription' : 'One-off',
              'quantity': entries
            }]
          }
        });

        // Data Layer Push for Begin Checkout event
        window.dataLayer.push({
          'event': 'begin_checkout',
          'ecommerce': {
            'user_id': userData?.userID ? userData?.userID : 'Guest User',
            'user_email': userData?.email ? userData?.email : 'Guest User',
            'items': [
              {
                'item_name': DrawData.drawName,
                'item_id': DrawData.drawID,
                'price': amount,
                'item_brand': 'Sport',
                'item_category': DrawData.drawCategoryID === 1 ? 'Fixed Cash Prize' : DrawData.drawCategoryID === 2 ? 'Split Cash Prize' : DrawData.drawCategoryID === 3 ? 'Experiential' : DrawData.drawCategoryID === 4 ? 'Hybrid' : '',
                'item_variant': !isoneoff ? 'Subscription' : 'One-off',
                'quantity': entries
              }
            ]
          }
        });

        const currency = 'GBP';
        const value = amount;
        addToCart(currency, value);
        initiateCheckout(currency, value);
    }
    const checkConsents = () => {
        if (!consent3) {
            toast("Please read and accept Terms & Conditions", { type: "error" });
        }
    }


    if (!secureLocalStorage.getItem('LogObject')) {
        console.log('Logout');
        navigate('/login');
    }

    return (
        <>
            {!paymentDetails && SubscriptionPlan ?
                <section className='subscription-plan font-face-sh'>
                    <div className='subscription-plan-container mx-auto' style={{ maxWidth: 700 }}>
                        <svg viewBox="0 0 1425 358" xmlns="http://www.w3.org/2000/svg" className="StepSubscriptionPlan_topBackground position-fixed top-0 start-0" style={{ width: "100vw", height: "25.12281vw" }}><path d="M1425 .84v207.845c0 197.772-1425 200.397-1425 0V.42l1425 .42z" fill="#c61d23" fillRule="nonzero" /></svg>
                        <div className={Freemodal ? 'steps-subscription-plan position-relative pt-5 d-none' : 'steps-subscription-plan position-relative pt-5 d-block'} style={{ zIndex: 999 }}>
                            <h2 className='step-subscription-plan-title text-light font-face-sh-bold display-6 text-center mb-3'>Pick your monthly plan</h2>
                            <div className='step-subscription-plan-description'>
                                <h3 className='step-subscription-plan-description_title text-light font-face-sh-bold fs-5 text-center mb-0'>How many entries do you want for each draw?</h3>
                                <p className='step-subscription-plan-description_text text-light text-center'>You can cancel or change your plan at any time.</p>
                            </div>
                            <div className='step-subscription-plan-selector pt-4'>
                                <ul className='d-flex unstyled list-unstyled gap-3'>
                                    <li className={isActive1 ? 'active' : ''} onClick={() => handleClick('first', 5, 5)}>
                                        <div className='ticket-title-sec bg-white px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                                            <img className='w-100' src={TicketImg1} style={{ height: 95 }} />
                                            <h4 className='fs-5 text-center mt-4 mb-0' style={{ color: '#302d51' }}>5 entries per draw</h4>
                                        </div>
                                        <div className='ticket-price d-flex branding-1 justify-content-center align-items-center py-2 mx-2' style={{ backgroundColor: '#e9eeed', borderRadius: '0 0 17px 17px' }}>
                                            <div className='subscription-price fs-5'><strong>£5</strong></div>
                                            <div className='subscription-period fs-6'>{isoneoff?"":"/month"}</div>
                                        </div>
                                    </li>
                                    <li className={isActive2 ? 'position-relative active' : 'position-relative'} onClick={() => handleClick('second', 10, 11)}>
                                        <div className='subscription-plan-emphasis text-light d-inline-block text-uppercase lh-normal position-absolute start-0 end-0 m-auto' style={{ background: '#7e0225', fontSize: 9, padding: '7px 6px 7px 7px', width: 85, top: -12, zIndex: 999 }}>Most Popular</div>
                                        <div className='ticket-title-sec bg-white px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                                            <img className='w-100' src={TicketImg2} style={{ height: 95 }} />
                                            <h4 className='fs-5 text-center mt-4 mb-0' style={{ color: '#302d51' }}>11 entries per draw</h4>
                                        </div>
                                        <div className='ticket-price d-flex branding-1 justify-content-center align-items-center py-2 mx-2' style={{ backgroundColor: '#e9eeed', borderRadius: '0 0 17px 17px' }}>
                                            <div className='subscription-price fs-5 '><strong>£10</strong></div>
                                            <div className='subscription-period fs-6 '>{isoneoff?"":"/month"}</div>
                                        </div>
                                    </li>
                                    <li className={isActive3 ? 'active' : ''} onClick={() => handleClick('third', 20, 25)}>
                                        <div className='ticket-title-sec bg-white px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                                            <img className='w-100' src={TicketImg3} style={{ height: 95 }} />
                                            <h4 className='fs-5 text-center mt-4 mb-0' style={{ color: '#302d51' }}>25 entries per draw</h4>
                                        </div>
                                        <div className='ticket-price d-flex branding-1 justify-content-center align-items-center py-2 mx-2' style={{ backgroundColor: '#e9eeed', borderRadius: '0 0 17px 17px' }}>
                                            <div className='subscription-price fs-5'><strong>£20</strong></div>
                                            <div className='subscription-period fs-6'>{isoneoff?"":"/month"}</div>
                                        </div>
                                    </li>
                                    <li onClick={() => setFreemodal(!Freemodal)}>
                                        <div className='ticket-title-sec bg-white px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                                            <img className='w-100' src={TicketImg1} style={{ height: 95 }} />
                                            <h4 className='fs-5 text-center mt-4 mb-0' style={{ color: '#302d51' }}>5 entries per draw</h4>
                                        </div>
                                        <div className='ticket-price d-flex branding-1 justify-content-center align-items-center py-2 mx-2' style={{ backgroundColor: '#e9eeed', borderRadius: '0 0 17px 17px' }}>
                                            <div className='subscription-price fs-5'><strong>free</strong></div>
                                            <div className='subscription-period fs-6'> postal entry</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='subscription-plan-selector-amountarea bg-white p-3 mt-4' style={{ borderRadius: 6 }}>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='subscription-plan-price d-flex justify-content-center'>
                                            <div className='subscription-plan-price_currency branding-1 fs-2 font-face-sh-bold mt-4 me-1'>£</div>
                                            <div className='subscription-plan-price_amount branding-1 display-3 font-face-sh-bold'>{amount}</div>
                                            <div className='subscription-plan-price_term text-black lh-1 mt-3 ms-1' style={{ fontSize: 12 }}>{isoneoff?"":<>per<br/>month</>}</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='subscription-plan-selector-entries d-flex align-items-center'>
                                            <div className='draw-entries-icon position-relative' style={{ backgroundColor: '#f3f2fb', width: 52, height: 52, borderRadius: '50%' }}></div>
                                            <ul className='unstyled list-unstyled ul-tick ms-3 mb-0' style={{ fontSize: 12 }}>
                                                <li>Auto-entry into every home draw</li>
                                                <li>Regular charitable support</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-check form-switch d-flex justify-content-center">
                                <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input me-2" type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label text-black" htmlFor="flexSwitchCheckDefault">{!isoneoff ? "Tick to make a one-off entry into the next draw" : "untick to make a Subscription entry into the next draw"}</label>
                            </div>
                            <div className='terms-cond mt-3 text-center'>
                                <p className='m-0'>Alternative entry routes available, <Link className='text-decoration-none branding-1 branding-1-hover' to={'/terms-conditions'}>click here</Link> for terms.</p>
                            </div>
                            <div className='step-subscription-button text-center mb-5 d-flex justify-content-center'>
                                <button onClick={() => toPaymentDetails(true)} className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center" style={{ width: 400, height: 48 }}>
                                    <span>Next</span>
                                    <img className='position-absolute top-0 bottom-0 my-auto' src={NextArrow} style={{ height: 40, right: 4 }} />
                                </button>
                            </div>
                        </div>
                        <div className={Freemodal ? 'free-entries-modal d-block position-relative' : 'free-entries-modal d-none'}>
                            <div className='free-entry-modal_title_block mb-5 pb-5'>
                                <img className='cross bg-white rounded-3 p-1 position-absolute end-0 top-0' src={Cross} style={{ width: 56, boxShadow: '0 4px 16px rgb(20 19 38 / 30%)', cursor: 'pointer' }} onClick={() => setFreemodal(!Freemodal)} />
                                <h2 className='free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5'>Free Prize Draw</h2>
                                <p className='free-entry-modal_text text-light text-center fs-5 mx-auto mt-5' style={{ maxWidth: 375 }}>While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.</p>
                            </div>
                            <div className='free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex' style={{ maxWidth: 375 }}>
                                <div className='free-entry-icon'>
                                    <img src={FreeEntryIcon} style={{ width: 24 }} />
                                </div>
                                <div className='free-entry-description ps-4 fs-14 lh-1'>
                                    <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                                    <p>* First Name<br />
                                        * Last Name<br />
                                        * Email</p>
                                    <p>to: PFW Holdings Ltd<br />Play Fund Win Office #300,<br />4 Blenheim Court,<br />Peppercorn Close,<br />Peterborough,<br />PE1 2DU<br /><br />You will be entered into the prize draws for the month in which we receive your post entries.<br /><br />Note: This will not result in any contributions to the Foundation</p>
                                    <div className='emoji-icon text-center pe-5 mt-4 pt-2'>
                                        <img className='mx-auto' src={require('../images/emoji_icon.png')} style={{ width: 66 }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                : paymentDetails ?
                    <section className='personal-details font-face-sh py-5'>
                        <div className='subscription-plan-container mx-auto' style={{ maxWidth: 780 }}>
                            <div className='row'>
                                <div className='col-lg-7 col-md-7 col-sm-12 step-user-details pe-5'>
                                    <div className='step-plan_step branding-1 rounded-pill text-center mx-auto' style={{ backgroundColor: '#f7f7fd', width: 112, height: 45, lineHeight: '45px' }}><strong>Step 2 of 2</strong></div>
                                    {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}
                                    <p className='text-center' style={{ color: '#808080', fontSize: '18px' }}>We’ll take your subscription amount each month.</p>
                                    <form method="post">
                                        {/* <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 15}}>Payment method</label> */}
                                        {/* <div className='text-light bg-branding-1 rounded-pill text-center mb-4' style={{width: 112, height: 45, lineHeight: '45px', fontSize: 14}}>Bank card</div> */}
                                        {/* <input type="text" name="cardnumber" id="cardnumber" placeholder="Card number" className="input mb-3" /> */}
                                        <div className='form-checkbox'>
                                            <ul className='checkbox-list list-unstyled'>
                                                <li className='checkbox-item mb-4'>
                                                    <label className='input-checkbox d-flex'>
                                                        <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                                                        <span className='input-checkbox_check'></span>
                                                        <span className='input-checkbox-label'>Yes, I’d like to receive updates about Supporters Draw via email.</span>
                                                    </label>
                                                </li>
                                                <li className='checkbox-item'>
                                                    <label className='input-checkbox d-flex'>
                                                        <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                                                        <span className='input-checkbox_check'></span>
                                                        <span className='input-checkbox-label'>Tick here to receive the latest news, team announcements, ticket alerts and product offers from England Rugby. By joining, England will keep you informed via one or more of email, SMS, and post. You will be able to refine your communication preferences or unsubscribe at any time.</span>
                                                    </label>
                                                </li>
                                                <li className='checkbox-item'>
                                                    <label className='step-payment-details_consent_description mt-5 input-checkbox d-flex' style={{ backgroundColor: 'rgb(198,29,35,.1)', color: '#302d51', padding: '14px 18px', borderRadius: 20, fontSize: 13 }}>
                                                        <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                                                        <span className='input-checkbox_check'></span>
                                                        <span>By clicking on Confirm you agree to Supporters Draw <Link to={"/terms-conditions"} style={{ color: '#302d51' }}>Terms & Conditions of Use</Link>, <Link to={"/privacy-policy"} style={{ color: '#302d51' }}>Privacy Policy</Link> and that you <strong>are at least 18 years old.</strong></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <p className='step-payment-details_consent_description mt-5' style={{ backgroundColor: 'rgb(198,29,35,.1)', color: '#302d51', padding: '14px 18px', borderRadius: 20, fontSize: 13 }}>By clicking on Confirm you agree to Super Hoops Draw <Link to={"/terms-conditions"} style={{ color: '#302d51' }}>Terms & Conditions of Use</Link>, <Link to={"/privacy-policy"} style={{ color: '#302d51' }}>Privacy Policy</Link> and that you <strong>are at least 18 years old.</strong></p> */}
                                        <div className='step-subscription-button text-center mb-5 d-flex justify-content-center'>
                                        </div>
                                    </form>
                                    {!consent3 ?
                                        <button onClick={checkConsents} className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center" style={{ width: '100%', height: 48 }}>
                                            Pay now</button>
                                        :
                                        <button onClick={() => { setStripe(true); setOneShow(true) }} className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center" style={{ width: '100%', height: 48 }}>
                                            Pay now</button>

                                    }
                                </div>
                                <div className='col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0'>
                                    <div className='user-details-info bg-branding-1 rounded-4' style={{ padding: '35px 35px 19px', boxShadow: '0 15px 50px -30px #000000' }}>
                                        <h3 className='text-light fs-5 mb-4'>Summary</h3>
                                        <div className='summary-info-impact pb-3'>
                                            <div className='summary-info-impact_title text-light d-flex align-items-center' style={{ fontSize: 18 }}>{entries} chances to win</div>
                                            <div className='summary-info-impact_period lh-1' style={{ color: 'rgb(255,255,255,0.7)', paddingLeft: 35 }}>every draw</div>
                                        </div>
                                        <hr className='bg-light w-100 border-0' style={{ height: 1, opacity: .1 }} />
                                        <div className='summary-info-price d-flex align-items-center pb-2'>
                                            <span className='summary-info-price_amount text-light display-4'>£ {amount}</span>
                                            <span className='summary-info-price_period ps-3' style={{ color: 'rgb(255,255,255,0.7)' }}>{isoneoff?"":"per month"}</span>
                                        </div>
                                        <hr className='bg-light w-100 border-0' style={{ height: 1, opacity: .1 }} />
                                        <p className='summary-info-upgrade-text m-0' style={{ color: 'rgb(255,255,255,0.7)' }}>Upgrade or cancel anytime</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    ""}
            {isStripe ?
                <Modal show={oneShow} onHide={() => setOneShow(false)} size="md" centered>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        <Elements stripe={stripePromise}>
                            <StripeForm
                                UserID={UserID}
                                show={true}
                                email={email}
                                name='-'
                                oneoff={isoneoff}
                                address1='-'
                                address2='-'
                                postcode='-'
                                amount={amount}
                                entries={entries}
                                hideModal={hideModal}
                                navigate={navigate}
                                timeout={timeout}
                            />
                        </Elements>
                    </Modal.Body>
                </Modal>
                : ""}

            <ToastContainer />
        </>
    )
}
