import React from 'react'
import NextArrow from '../images/next-arrow.svg'

export default function PersonalDetails() {
    let amount = 0;

  return (
    <>
        <section className='personal-details font-face-sh py-5'>
            <div className='subscription-plan-container mx-auto' style={{maxWidth: 780}}>
                <div className='row'>
                    <div className='col-lg-7 col-md-7 col-sm-12 step-user-details pe-5'>
                        <div className='step-plan_step branding-1 rounded-pill text-center mx-auto' style={{backgroundColor: '#f7f7fd', width: 112, height: 45, lineHeight: '45px'}}><strong>Step 1 of 2</strong></div>
                        <h2 className='text-black font-face-sh-bold mt-3 text-center'>Personal details</h2>
                        <p className='text-center' style={{color: '#808080', fontSize: '18px'}}>We just need to capture a few personal details to get you going.</p>
                        <form method="post">
                            <input type="text" name="fullName" id="fullName" placeholder="Full Name" className="input mb-3" />
                            <input type="tel" name="dateOfBirth" id="dateOfBirth" placeholder="Date of birth (DD/MM/YYYY)" className="input mb-3" />
                            <div className='form-radio-box'>
                                <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 14}}>Gender</label>
                                <ul className='radio-list list-unstyled d-flex gap-3'>
                                    <li className='radio-item'>
                                        <label className='input-radio'>
                                            <input type="radio" name="gender" value="1" />
                                            <span className='input-radio-label'>Male</span>
                                        </label>
                                    </li>
                                    <li className='radio-item'>
                                        <label className='input-radio'>
                                            <input type="radio" name="gender" value="2" />
                                            <span className='input-radio-label'>Female</span>
                                        </label>
                                    </li>
                                    <li className='radio-item'>
                                        <label className='input-radio'>
                                            <input type="radio" name="gender" value="3" />
                                            <span className='input-radio-label'>Other</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className='step-subscription-button text-center mb-5 d-flex justify-content-center'>
                                <button type='submit' className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center" style={{width: '100%', height: 48}}>
                                    <span>Next</span>
                                    <img className='position-absolute top-0 bottom-0 my-auto' src={NextArrow} style={{height: 40, right: 4}} />
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className='col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0'>
                        <div className='user-details-info bg-branding-1 rounded-4' style={{padding: '35px 35px 19px', boxShadow: '0 15px 50px -30px #000000'}}>
                            <h3 className='text-light fs-5 mb-4'>Summary</h3>
                            <div className='summary-info-impact pb-3'>
                                <div className='summary-info-impact_title text-light d-flex align-items-center' style={{fontSize: 18}}>11 chances to win</div>
                                <div className='summary-info-impact_period lh-1' style={{color: 'rgb(255,255,255,0.7)', paddingLeft: 35}}>every draw</div>
                            </div>
                            <hr className='bg-light w-100 border-0' style={{height: 1,opacity: .1}} />
                            <div className='summary-info-price d-flex align-items-center pb-2'>
                                <span className='summary-info-price_amount text-light display-4'>£ {amount}</span>
                                <span className='summary-info-price_period ps-3' style={{color: 'rgb(255,255,255,0.7)'}}>per month</span>
                            </div>
                            <hr className='bg-light w-100 border-0' style={{height: 1,opacity: .1}} />
                            <p className='summary-info-upgrade-text m-0' style={{color: 'rgb(255,255,255,0.7)'}}>Upgrade or cancel anytime</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
