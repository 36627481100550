import React, { Component } from "react";
import Slider from "react-slick";
import TrophyIcon from "../images/trophy-icon.svg";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
}

export default function TestimonialSlider() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // cssEase: "linear"
  };
  return (
    <div className="story-block-inner bg-white px-5 py-5 shadow-lg rounded-3">
      <Slider {...settings}>
          <div className="story-block">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/story/1.jpg')} alt="alan"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Alan B.</h4>
                  <p style={{fontSize: 14}}>“I was delighted to be a winner in the draw and happy to support the foundation!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Signed Red Roses Shirt</p>
                </div>
              </div>
            </div>
          </div>
          <div className="story-block">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/story/2.jpg')} alt="colin"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Colin H.</h4>
                  <p style={{fontSize: 14}}>“I was amazed to win a prize first time round!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Ball Signed by The Red Roses</p>
                </div>
              </div>
            </div>
          </div>

          <div className="story-block">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/story/3.jpg')} alt="craig"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Craig B.</h4>
                  <p style={{fontSize: 14}}>“I am pleased to have been able to do my bit and over the moon to have won a prize!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">£441.95</p>
                </div>
              </div>
            </div>
            </div>

            <div className="story-block">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/story/4.jpg')} alt="Kirsty"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Kirsty N.</h4>
                  <p style={{fontSize: 14}}>“Thank you Supporters Draw for everything you do to support injured players!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Twickenham Stadium Tour</p>
                </div>
              </div>
            </div>
            </div>

            <div className="story-block">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3">
                  <img className='w-100 rounded-3' src={require('../images/story/5.jpg')} alt="Steven"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Steven P.</h4>
                  <p style={{fontSize: 14}}>“I was thrilled to win a signed match ball having got the news while watching the lads put in another great performance!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Signed Match Ball</p>
                </div>
              </div>
            </div>
            </div>

      </Slider>
    </div>
  );
}
